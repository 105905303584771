var api = "https://api.qingcigame.com"
const config = {
    game_id: 87,
    cate_id: 103,
    ios_link: '',
    android_link: '',
    taptap_link: 'https://l.taptap.cn/084EFnmB?channel=rep-rep_fta0ds7jyja',
    game_link: 'https://www.3839.com/a/90150.htm' // 好游快爆
}

var obj = {
    index: function () {
        this.flexible() // 自适应
        this.preloading() // 预加载
        this.swiper() // 轮播图相关
        this.linkInit() // 下载链接
        this.btn() // 页面按钮事件绑定
        this.indexInit() // 接口
        this.share() // 分享
    },
    information: function () {
        this.flexible() // 自适应
        this.informationBtn() // 页面按钮事件绑定
        this.informationInit() // 接口
        this.share() // 分享
    },
    newsDetail: function () {
        this.flexible() // 自适应
        this.newsDetailBtn() // 页面按钮事件绑定
        this.newsDetailInit() // 接口
        this.share() // 分享
    },
    // 自适应
    flexible: function () {
        $(function () {
            function t() {
                var t = $("html").width()
                if (t <= 1400) {
                    $("html").css({ "font-size": (35 / 1400) * t + "px" })
                } else if (t <= 1920) {
                    $("html").css({ "font-size": "35px" })
                } else if (t <= 2560) {
                    $("html").css({ "font-size": (t - 1920) / 32 + 35 + "px" })
                }
            }
            $(window).resize(t), t()
        })
    },
    // 预加载
    preloading: function () {
        var queue = new createjs.LoadQueue()
        queue.on("progress", function (e) {
            //加载中的进度
            let num = parseInt(e.progress * 100)
            let left = `translate(${num - 100}%, -1rem)`
            let right = `translate(${100 - num}%, -1rem)`
            $("#preloading .process .num").html(num)
            $('#preloading .line.left').css('transform', left)
            $('#preloading .line.right').css('transform', right)
        }, this)
        queue.on("complete", function (e) {
            //加载完成执行操作
            $('#preloading').fadeOut()
            setTimeout(() => {
                $('#preloading').remove()
            }, 1000)
            $('#content').css('opacity', 1)
        }, this)
        queue.loadManifest([
            //加载图片          
            '/official/images/pc/aside_bg-4a861c980e.png',
            '/official/images/pc/aside_toggle-7cb17d4163.png',
            '/official/images/pc/aside_icon-88afd3f5e8.png',
            '/official/images/pc/aside_btn-14296d30ae.png',
            '/official/images/pc/content_01_bg-1e6047bc9a.png',
            '/official/images/pc/content_01_year-3d16cc5b86.png',
            '/official/images/pc/content_01_start-d05c308917.png',
            '/official/images/pc/content_01_next_btn-b426bc1f73.png',
            '/official/images/pc/content_01_public-37c8e47208.png',
            '/official/images/pc/ios_btn-e4ca532860.png',
            '/official/images/pc/android_btn-a7ec0d6e88.png',
            '/official/images/pc/content_01_taptap-2332fc3bcd.png',
            '/official/images/pc/content_01_game-5c6f5b2a70.png',
            '/official/images/pc/content_01_group-4d73910f6c.png',
            '/official/images/pc/content_01_connect_bottom-43c701293b.png'
        ])
    },
    // 轮播图相关
    swiper: function () {
        // 全局切换
        let index = window.location.search.split('index=')[1]
        index ? index = index.split('&')[0] : 0
        var swiper1 = new Swiper('.swiper1', {
            direction: 'vertical',
            resistanceRatio: 0,
            mousewheel: true,
            speed: 500,
            initialSlide: index,
            on: {
                slideChangeTransitionEnd: function () {
                    $('.aside .item').eq(this.realIndex).addClass('current').siblings().removeClass('current')
                }
            }
        })
        // 侧栏
        $('.aside .item').click(function () {
            swiper1.slideTo($(this).index())
        })
        // 首页-底部按钮
        $('.content-01 .next-btn').click(() => {
            swiper1.slideTo(1)
        })
        // 游戏特色页-轮播图
        setTimeout(() => {
            var swiper3 = new Swiper('.swiper3', {
                loop: true,
                effect: 'coverflow',
                slidesPerView: 'auto',
                centeredSlides: true,
                slideToClickedSlide: true,
                autoplay: {
                    disableOnInteraction: false,
                },
                coverflowEffect: {
                    rotate: 30,
                    depth: 300,
                    slideShadows: false
                },
                navigation: {
                    nextEl: '.swiper3-next-btn',
                    prevEl: '.swiper3-prev-btn',
                },
                pagination: {
                    el: '.swiper3-pagination'
                }
            })
        }, 500)
    },
    linkInit: function () {
        this.onAjax({
            url: `${api}/get_url`,
            type: 'GET',
            data: {
                game_id: config.game_id
            },
            success: function (res) {
                if (res.code == 200) {
                    // ios
                    res.data.ios_down_url ? config.ios_link = res.data.ios_down_url : config.ios_link = 'javascript: void(0);'
                    // android
                    res.data.android_down_url ? config.android_link = res.data.android_down_url : config.android_link = 'javascript: void(0);'
                }
            },
            error: function (json) {
                layer.msg("服务器请求失败", { time: 2000 })
            },
            complete: function () {
                layer.close(index)
            }
        })
    },
    // 跳转
    onLink: function (link) {
        if (!link || link === 'javascript: void(0);') {
            layer.msg("敬请期待", { time: 2000 })
        } else {
            window.location.href = link
        }
    },
    // 首页-页面按钮事件绑定
    btn: function () {
        $('.ios-btn').click(() => {
            obj.countNum('刃心官网_pc端_点击ios下载按钮')
            obj.onLink(config.ios_link)
        })
        $('.android-btn').click(() => {
            obj.countNum('刃心官网_pc端_点击android下载按钮')
            obj.onLink(config.android_link)
        })
        $('.taptap-btn').click(() => {
            obj.countNum('刃心官网_pc端_点击taptap按钮')
            obj.onLink(config.taptap_link)
        })
        $('.game-btn').click(() => {
            obj.countNum('刃心官网_pc端_点击好游快爆按钮')
            obj.onLink(config.game_link)
        })
        // 侧栏显示或隐藏
        $('.aside .btn').click(() => {
            $('.aside').toggleClass('close')
        })
        // 首页-播放按钮
        $('.content-01 .start-btn').click(function () {
            $(this).hide()
            $('.content-01 .video').css('opacity', 1)
            $('.content-01 .video')[0].play()
            obj.countNum('刃心官网_pc端_点击PV视频播放按钮')
        })
        // 首页-适龄提示按钮
        $('.content-01 .year').click(() => {
            $('.popup.year').fadeIn()
            obj.countNum('刃心官网_pc端_点击适龄提示按钮')
        })
        // 首页-一键加群按钮
        $('.content-01 .group').click(() => {
            $('.popup.group-code').fadeIn()
            obj.countNum('刃心官网_pc端_点击添加官方群按钮')
        })
        // 游戏咨询页-公告按钮
        $('.content-02 .sort .notice').click(function () {
            $(this).addClass('current').siblings().removeClass('current')
            $('.content-02 .right .text .notice').show().siblings().hide()
        })
        // 游戏咨询页-活动按钮
        $('.content-02 .sort .activity').click(function () {
            $(this).addClass('current').siblings().removeClass('current')
            $('.content-02 .right .text .activity').show().siblings().hide()
        })
        // 游戏咨询页-更多按钮
        $('.content-02 .sort .more').click(() => {
            window.location = 'information.html'
        })
        // 关注我们页-公众号按钮
        $('.content-04 .wx .inner').click(() => {
            $('.popup.wx-code').fadeIn()
            obj.countNum('刃心官网_pc端_点击关注公众号按钮')
        })
        // 关注我们页-官方群按钮
        $('.content-04 .group .inner').click(() => {
            $('.popup.group-code').fadeIn()
            obj.countNum('刃心官网_pc端_点击添加官方群按钮')
        })
        // 关注我们页-官方客服按钮
        $('.content-04 .service .inner').click(() => {
            $('.popup.service-code').fadeIn()
            obj.countNum('刃心官网_pc端_点击添加客服按钮')
        })
        // 关闭弹出层
        $('.popup').click(function (e) {
            if (e.target === this || e.target.className === 'close-btn') $(this).fadeOut()
        })
    },
    // 游戏资讯页-页面按钮事件绑定
    informationBtn() {
        $('#information').css('opacity', 1)
        // 侧栏显示或隐藏
        $('.aside .btn').click(() => {
            $('.aside').toggleClass('close')
        })
        // 侧栏选项
        $('.aside .item').click(function () {
            let index = $(this).index()
            window.location = `index.html?index=${index}`
        })
        // 头部菜单
        $('.menu').click(function (e) {
            switch (e.target.className) {
                // 返回按钮
                case 'back-btn':
                    window.location = `index.html`
                    break;
                // 官网首页
                case 'home':
                    window.location = `index.html`
                    break;
                // 游戏资讯
                case 'news':
                    window.location = `index.html?index=1`
                    break;
            }
        })
        // 头部分类选项
        $('.sort .item').click(function () {
            $(this).addClass('current').siblings().removeClass('current')
            switch ($(this).index()) {
                case 0:
                    config.cate_id = 103
                    break;
                case 1:
                    config.cate_id = 104
                    break;
                case 2:
                    config.cate_id = 105
                    break;
            }
            obj.informationInit()
        })
        // 上一页
        $('.page .prev-btn').click(() => {
            let current = Number($('.page .current').text())
            if (current > 1) {
                current--
                $('.page .current').text(current)
                this.onAjax({
                    url: `${api}/web/article`,
                    type: 'GET',
                    data: {
                        page: current,
                        type: 0,
                        limit: 10,
                        cate_id: config.cate_id,
                        game_id: config.game_id
                    },
                    success: function (res) {
                        if (res.code == 200) {
                            let total = res.data.articleList
                            $('.content').html('')
                            total.data.forEach(item => {
                                switch (item.cate_id) {
                                    case 104:
                                        var text = '公告'
                                        break;
                                    case 105:
                                        var text = '活动'
                                        break;
                                }
                                $('.content').append(`
                                    <a class="item" href="news_detail.html?id=${item.id}">
                                        <span class="text">【${text}】${item.article_title}</span>
                                        <span class="date">${item.created_at}</span>
                                    </a>
                                `)
                            })
                            $('.page .total').text(total.last_page)
                        }
                    },
                    error: function (json) {
                        layer.msg("服务器请求失败", { time: 2000 })
                    },
                    complete: function () {
                        layer.close(index)
                    }
                })
            }
        })
        // 下一页
        $('.page .next-btn').click(() => {
            let current = Number($('.page .current').text())
            let total = Number($('.page .total').text())
            if (current < total) {
                current++
                $('.page .current').text(current)
                this.onAjax({
                    url: `${api}/web/article`,
                    type: 'GET',
                    data: {
                        page: current,
                        type: 0,
                        limit: 10,
                        cate_id: config.cate_id,
                        game_id: config.game_id
                    },
                    success: function (res) {
                        if (res.code == 200) {
                            let total = res.data.articleList
                            $('.content').html('')
                            total.data.forEach(item => {
                                switch (item.cate_id) {
                                    case 104:
                                        var text = '公告'
                                        break;
                                    case 105:
                                        var text = '活动'
                                        break;
                                }
                                $('.content').append(`
                                    <a class="item" href="news_detail.html?id=${item.id}">
                                        <span class="text">【${text}】${item.article_title}</span>
                                        <span class="date">${item.created_at}</span>
                                    </a>
                                `)
                            })
                            $('.page .total').text(total.last_page)
                        }
                    },
                    error: function (json) {
                        layer.msg("服务器请求失败", { time: 2000 })
                    },
                    complete: function () {
                        layer.close(index)
                    }
                })
            }
        })
        // 置顶按钮
        $('body').scroll(() => {
            if ($('body').scrollTop() >= $('.banner').innerHeight()) {
                $('.to-top').css('display', 'block')
            } else {
                $('.to-top').css('display', 'none')
            }
        })
        $('.to-top').click(() => {
            $('html, body').animate({
                scrollTop: 0
            }, 200, 'linear')
        })
    },
    // 新闻详情页-页面按钮事件绑定
    newsDetailBtn() {
        $('#news-detail').css('opacity', 1)
        // 头部菜单
        $('.menu').click(function (e) {
            switch (e.target.className) {
                case 'back-btn':
                    window.history.go(-1)
                    break;
                case 'sort-01':
                    window.location = `index.html`
                    break;
                case 'sort-02':
                    window.location = `information.html`
                    break;
            }
        })
    },
    // 首页初始化
    indexInit() {
        // 公告
        this.onAjax({
            url: `${api}/web/blade1/index`,
            type: 'GET',
            data: {
                game_id: config.game_id
            },
            success: function (res) {
                if (res.code == 200) {
                    // 公告
                    let notice = res.data.articleList.gong_gao
                    notice.forEach(item => {
                        $('.content-02 .right .text .notice').append(`
                            <a href="news_detail.html?id=${item.id}" class="item">
                                <span class="left"></span>
                                <span class="center">${item.article_title}</span>
                                <span class="right">${item.created_at}</span>
                            </a>
                        `)
                    })
                    // 活动
                    let activity = res.data.articleList.activity_news
                    activity.forEach(item => {
                        $('.content-02 .right .text .activity').append(`
                            <a href="news_detail.html?id=${item.id}" class="item">
                                <span class="left"></span>
                                <span class="center">${item.article_title}</span>
                                <span class="right">${item.created_at}</span>
                            </a>
                        `)
                    })
                }
            },
            error: function (json) {
                layer.msg("服务器请求失败", { time: 2000 })
            },
            complete: function () {
                layer.close(index)
            }
        })
        // 轮播图
        this.onAjax({
            url: `${api}/web/news/info`,
            type: "GET",
            data: {
                game_id: 87
            },
            success: function (res) {
                if (res.code == 200) {
                    let image = res.data.article.game_information
                    image.forEach(item => {
                        $('.swiper2 .swiper-wrapper').append(`
                            <div class="swiper-slide">
                                <a href="${item.jump_url}"></a>
                                <img src="${item.pc_image}"
                                    alt="">
                                <div class="title">${item.name}</div>
                            </div>
                        `)
                    })
                    // 游戏咨询页-轮播图
                    var swiper2 = new Swiper('.swiper2', {
                        loop: true,
                        autoplay: {
                            disableOnInteraction: false,
                        },
                        pagination: {
                            el: '.swiper2-pagination'
                        }
                    })
                }
            },
            error: function (json) {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function () {
            }
        })
    },
    // 游戏资讯页初始化
    informationInit() {
        this.onAjax({
            url: `${api}/web/article`,
            type: 'GET',
            data: {
                page: 1,
                type: 0,
                limit: 10,
                cate_id: config.cate_id,
                game_id: config.game_id
            },
            success: function (res) {
                if (res.code == 200) {
                    let total = res.data.articleList
                    $('.content').html('')
                    total.data.forEach(item => {
                        switch (item.cate_id) {
                            case 104:
                                var text = '公告'
                                break;
                            case 105:
                                var text = '活动'
                                break;
                        }
                        $('.content').append(`
                            <a class="item" href="news_detail.html?id=${item.id}">
                                <span class="text">【${text}】${item.article_title}</span>
                                <span class="date">${item.created_at}</span>
                            </a>
                        `)
                    })
                    $('.page').css('display', 'flex').find('.total').text(total.last_page)
                }
            },
            error: function (json) {
                layer.msg("服务器请求失败", { time: 2000 })
            },
            complete: function () {
                layer.close(index)
            }
        })
    },
    // 新闻详情页初始化
    newsDetailInit() {
        let id = window.location.search.split('id=')[1].split('&')[0]
        this.onAjax({
            url: `${api}/web/article/${id}`,
            type: 'GET',
            success: function (res) {
                if (res.code == 200) {
                    let list = res.data.list
                    let sort_02 = list.one_level_name.split('刃心1-')[1] ?? list.one_level_name
                    let sort_03 = list.cate_name.split('刃心1-')[1] ?? list.cate_name
                    list.one_level_name ? $('.site .sort-02').html(sort_02) : ''
                    list.cate_name ? $('.site .sort-03').html(sort_03) : ''
                    $('.title').html(list.article_title)
                    $('.date').html(list.created_at)
                    $('.main').html(list.article_content)
                }
            },
            error: function (json) {
                layer.msg("服务器请求失败", { time: 2000 })
            },
            complete: function () {
                layer.close(index)
            }
        })
    },
    // 简易封装常用ajax
    onAjax: function (options) {
        const { url, type, success, error, data } = options
        $.ajax({
            url: url,
            type: type,
            data: data,
            success: function (json) {
                if (json.code !== 200) {
                    var message = json.message
                    layer.msg(message)
                    return false
                }
                success && success(json)
            },
            error: function (err) {
                layer.msg(err.message)
                error && error(err)
            }
        })
    },
    // 微信分享
    share: function () {
        var share_icon = "https://download4.qcplay.com/qcgame-api/LCTzwRCJNoga9La9pYW1v7UR0WYRxO4IwMBSdaxs.png"
        var share_big_title = "《忍心》官网"
        var share_min_title = "忍心"
        var share_link = window.location.href
        outside.share(share_icon, share_big_title, share_min_title, share_link)
    },
    // 阿里云埋点 
    countNum: function (data) {
        setTimeout(() => {
            try {
                __bl.sum(data);
            } catch (e) {
                console.log(e)
            }
        }, 500);
    }
}